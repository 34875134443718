import GameModel from '../models/gameModel';
import DataProvider from './api';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import dataParser from './dataParser';
import eEventTypes from '../enums/eEventTypes';
import eServerActionTypes from '../enums/eServerActionTypes';

export default new class ServerManager {
  constructor() {
    this.dataProvider = new DataProvider();
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add('dataProvider:updateBalance', this.onUpdateBalance.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_ROLLING_STATE_START, this.onStartSpin.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_SEND_BOMB_REQUEST, this.useBomb.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_BUY_BONUS, this.buyBonus.bind(this));
  }

  async onInit() {
    let request = {
      action: eServerActionTypes.ESAT_INIT
    };
    const data = await this.dataProvider.sendInit(request);
    const parsedData = dataParser.parseInit(data);
    GlobalDispatcher.dispatch('dataProvider:hasResponse', parsedData);
    GameModel.setNewData(parsedData);
  }

  async onStartSpin() {
    let request = {
      betIndex: GameModel.betIndex,
      action: eServerActionTypes.ESAT_SPIN
    };
    const data = await this.dataProvider.sendMessage(request, eServerActionTypes.ESAT_SPIN);
    const parsedData = dataParser.parseSpin(data);
    GlobalDispatcher.dispatch('dataProvider:hasResponse', parsedData);
    GameModel.setNewData(parsedData);
    return data;
  }

  async useBomb() {
    let request = {
      betIndex: GameModel.betIndex,
      action: eServerActionTypes.ESAT_USE_BOMB,
    };
    const data = await this.dataProvider.sendMessage(request, eServerActionTypes.ESAT_USE_BOMB);
    const parsedData = dataParser.parseFeature(data);
    GlobalDispatcher.dispatch('dataProvider:hasResponse', parsedData);
    GameModel.setNewData(parsedData);
    return data;
  }

  async buyBonus() {
    let request = {
      betIndex: GameModel.betIndex,
      type: GameModel.bonusPurchaseData.type,
      action: eServerActionTypes.ESAT_BUY
    };
    const data = await this.dataProvider.sendMessage(request, eServerActionTypes.ESAT_BUY);
    const parsedData = dataParser.parsePurchase(data);
    GlobalDispatcher.dispatch('dataProvider:hasResponse', parsedData);
    GameModel.setNewData(parsedData);
    return data;
  }

  async onUpdateBalance() {
    const data = await this.dataProvider.sendBalance();
    GameModel.balance = data.balance;
  }

  onResponse(data) {
    GameModel.setNewData(data.params);
  }
}

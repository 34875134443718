export default {
  H: {
    offsets: {
      right_panel: { top: 0, left: -11 },
      tournaments_panel: {
        top: 0,
        left: -1430,
      }
    }
  },
  V: {
    offsets: {
      top: 55,
      left: 0,
      tournaments_panel: {
        top: -85,
        left: -590,
      }
    }
  }
}
